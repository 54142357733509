<template>
	<el-drawer :size="((theme.width > 580 ? (580/theme.width) : 1)*100)+'%'" :title="enrollment.name || '创建档案'" :visible.sync="enrollment_dialog" append-to-body destroy-on-close>
		<div class="scroll-wrapper oa pr10 pl10 pb20" v-if="enrollment_dialog">
			<div class="pr" v-if="is_show">
				<el-descriptions :labelStyle="{width: '70px'}" :column="2" :title="title" :size="theme.size" border>
					<el-descriptions-item label="编号">{{enrollment.no}}</el-descriptions-item>
					<el-descriptions-item label="类型">{{fields.types[enrollment.type] || '未知'}}</el-descriptions-item>
					<el-descriptions-item label="证件类型" v-if="!fields.id_types[enrollment.id_type]">未知</el-descriptions-item>
					<el-descriptions-item :label="fields.id_types[enrollment.id_type]" v-else>{{enrollment.id_number}}</el-descriptions-item>
					<el-descriptions-item label="性别">{{genders[enrollment.gender] || '未知'}}</el-descriptions-item>
					<el-descriptions-item label="婚姻">{{fields.marriages[enrollment.marriage]}}</el-descriptions-item>
					<el-descriptions-item label="民族">{{fields.nations[enrollment.nation] || '未知'}}</el-descriptions-item>
					<el-descriptions-item label="籍贯">{{enrollment.place ? enrollment.place.name : '未知'}}</el-descriptions-item>
					<el-descriptions-item label="户籍">{{enrollment.census ? enrollment.census.name : '未知'}}</el-descriptions-item>
					<el-descriptions-item label="政治">{{fields.politics[enrollment.politics] || '未知'}}</el-descriptions-item>
					<el-descriptions-item label="学历">{{fields.edus[enrollment.education] || '未知'}}</el-descriptions-item>
					<el-descriptions-item label="学校">{{enrollment.graduation || '-'}}</el-descriptions-item>
					<el-descriptions-item label="专业">{{enrollment.profession || '-'}}</el-descriptions-item>
					<el-descriptions-item label="出生日期">{{enrollment.birthed_at}}</el-descriptions-item>
					<el-descriptions-item label="入职日期">{{enrollment.hired_at}}</el-descriptions-item>
					<el-descriptions-item label="手机号码">{{enrollment.mobile}}</el-descriptions-item>
					<el-descriptions-item label="紧急联系">{{enrollment.emergency_mobile}}，{{enrollment.emergency_name}}</el-descriptions-item>
					<el-descriptions-item label="电子邮箱">{{enrollment.email}}</el-descriptions-item>
					<el-descriptions-item label="银行账户">{{enrollment.deposit_bank || '-'}}<br>{{enrollment.bank_number || ''}}</el-descriptions-item>
					<el-descriptions-item label="户籍地址" :span="2">{{enrollment.residence_address || '-'}}</el-descriptions-item>
					<el-descriptions-item label="现居地址" :span="2">{{enrollment.current_address || '-'}}</el-descriptions-item>
					<el-descriptions-item :span="2" :label="attach" v-for="(attach, meta) in enrollment_attachs" :key="meta">
						<div v-if="enrollment.attachments && enrollment.attachments.length">
							<p v-for="attachment in enrollment.attachments.filter(a => a.meta == meta)" :key="attachment.id">
								<el-link type="primary" @click="openFile(attachment.url)">{{attachment.name}}</el-link>
							</p>
						</div>
						<p v-else>-</p>
					</el-descriptions-item>
					<el-descriptions-item label="其它附件" :span="2">
						<div v-if="enrollment.attachments && enrollment.attachments.length">
							<p v-for="attachment in enrollment.attachments.filter(a => a.meta == 0)" :key="attachment.id">
								<el-link type="primary" @click="openFile(attachment.url)">{{attachment.name}}</el-link>
							</p>
						</div>
						<p v-else>无</p>
					</el-descriptions-item>
				</el-descriptions>
				<div class="fs12 mt10 c9">根据《关于渐进式延迟退休年龄的通知》{{retirement_text}}</div>
				<div class="mt10" v-if="(person || enrollment.who == 'hr') && enrollment.status == 4">
					<el-button type="primary" size="mini" @click="enrollment.id = 0;">更新资料</el-button>
					<el-button type="danger" size="mini" @click="enrollment_dialog = false; $emit('departure', {enrollment_id: enrollment.id, employee_id: enrollment.employee_id, who: enrollment.who || ''})">离职申请</el-button>
				</div>
				<div class="pa fs38 fwb" style="color: rgba(255, 0, 0, 0.2); top: 100px; left: 50%; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="fields.status[enrollment.status]">{{fields.status[enrollment.status]}}</div>
			</div>
			<el-form label-width="90px" ref="enrollment_form" :model="enrollment" :rules="enrollment_rules" :size="theme.size" v-else status-icon>
				<el-form-item label="类型" prop="type">
					<el-radio-group v-model="enrollment.type">
						<el-radio-button v-for="(type, t) in fields.types" :label="+t" :key="t">{{type}}</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="真实姓名" style="margin-bottom: 0;" required>
					<el-col :span="14">
						<el-form-item prop="name">
							<el-input v-model="enrollment.name" placeholder="请输入真实姓名" clearable>
								<el-switch slot="append" v-model="enrollment.gender" :active-value="2" :inactive-value="1" active-text="女" inactive-text="男"></el-switch>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="8">
						<el-form-item prop="birthed_at">
							<el-date-picker style="width: 100%;" v-model="enrollment.birthed_at" :editable="false" type="date" placeholder="请选择出生日期"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="证件号码" prop="id_number">
					<el-input v-model="enrollment.id_number" placeholder="请输入证件号码" clearable>
						<el-select slot="prepend" style="width: 120px;" v-model="enrollment.id_type" placeholder="证件类型" filterable>
							<el-option v-for="(type, t) in fields.id_types" :key="t" :label="type" :value="+t"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label="民族/婚姻" style="margin-bottom: 0;" required>
					<el-col :span="8">
						<el-form-item prop="nation">
							<el-select v-model="enrollment.nation" placeholder="民族" filterable>
								<el-option v-for="(nation, n) in fields.nations" :key="n" :label="nation" :value="+n"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="14">
						<el-form-item prop="marriage">
							<el-radio-group v-model="enrollment.marriage">
								<el-radio-button v-for="(marriage, m) in fields.marriages" :label="+m" :key="m">{{marriage}}</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="籍贯/户籍" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="place_id">
							<el-select style="width: 100%;" v-model="enrollment.place_id" placeholder="出生首次登记归属地" filterable clearable>
								<el-option label="无" :value="0"></el-option>
								<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="census_id">
							<el-select style="width: 100%;" v-model="enrollment.census_id" placeholder="身份证件签发归属地" filterable clearable>
								<el-option label="无" :value="0"></el-option>
								<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="户籍地址" prop="residence_address">
					<el-input v-model="enrollment.residence_address" placeholder="请输入户籍地址" clearable></el-input>
				</el-form-item>
				<el-form-item label="现居地址" prop="current_address">
					<el-input v-model="enrollment.current_address" placeholder="请输入现居地址" clearable></el-input>
				</el-form-item>
				<el-form-item label="入职日期" prop="hired_at">
					<el-date-picker style="width: 100%;" v-model="enrollment.hired_at" :editable="false" type="date" placeholder="请选择入职日期"></el-date-picker>
				</el-form-item>
				<el-form-item label="手机/邮箱" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="mobile">
							<el-input v-model="enrollment.mobile" placeholder="请输入手机号码" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="email">
							<el-input v-model="enrollment.email" placeholder="请输入电子邮箱" clearable></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="紧急联系" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="emergency_name">
							<el-input v-model="enrollment.emergency_name" placeholder="请输入紧急联系人" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="emergency_mobile">
							<el-input v-model="enrollment.emergency_mobile" placeholder="请输入紧急联系手机" clearable></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="政治/学历" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="politics">
							<el-select style="width: 100%;" v-model="enrollment.politics" placeholder="政治面貌" filterable>
								<el-option v-for="(politics, p) in fields.politics" :key="p" :label="politics" :value="+p"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="education">
							<el-select style="width: 100%;" v-model="enrollment.education" placeholder="最高学历" filterable>
								<el-option v-for="(education, e) in fields.edus" :key="e" :label="education" :value="+e"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="学校/专业" style="margin-bottom: 0;">
					<el-col :span="11">
						<el-form-item prop="graduation">
							<el-input v-model="enrollment.graduation" placeholder="请输入毕业学校" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="profession">
							<el-input v-model="enrollment.profession" placeholder="请输入毕业专业" clearable></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="银行卡号" style="margin-bottom: 0;">
					<el-col :span="11">
						<el-form-item prop="deposit_bank" :rules="[{ required: enrollment.bank_number ? true : false, message: '请输入开户银行', trigger: 'blur'}]">
							<el-input v-model="enrollment.deposit_bank" placeholder="请输入开户银行" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="bank_number" :rules="[{ required: enrollment.deposit_bank ? true : false, message: '请输入银行卡号', trigger: 'blur'}]">
							<el-input v-model="enrollment.bank_number" placeholder="请输入银行卡号" clearable></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item :label="attach" v-for="(attach, meta) in enrollment_attachs" :key="meta">
					<el-upload
						:action="$api.URI_UPLOADS"
						:data="{type: enrollments_cfg.type, meta}"
						:accept="enrollments_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l, meta); }"
						:on-preview="(f) => { openFile(f.url); }"
						:on-remove="onRemove"
						:file-list="enrollment.attachments.filter(a => a.meta == meta)">
						<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
						<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{enrollments_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{enrollments_cfg.format_size_unit}}</p>
					</el-upload>
				</el-form-item>
				<el-form-item label="其它附件">
					<el-upload
						:action="$api.URI_UPLOADS"
						:data="{type: enrollments_cfg.type}"
						:accept="enrollments_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l, 0); }"
						:on-preview="(f) => { openFile(f.url); }"
						:on-remove="onRemove"
						:file-list="enrollment.attachments.filter(a => a.meta == 0)"
						multiple>
						<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
						<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{enrollments_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{enrollments_cfg.format_size_unit}}</p>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('enrollment_form')">{{enrollment.who == 'hr' ? '提交保存' : '提交审核'}}</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- <departure-show ref="departure"></departure-show> -->
	</el-drawer>
</template>

<style>
	.e-attachments p {
		cursor: pointer;
		padding: 5px 10px;
		border-radius: 5px;
	}
	.e-attachments p:hover {
		background-color: #f1f1f1;
	}
	.e-attachments p:hover span {
		color: #b7a06a;
	}
</style>

<script>
	import { mapState } from 'vuex';
	// import departureShow from '@/pages/hrm/departures/show';
	import moment from 'moment';

	export default {
		// components: {
		// 	departureShow
		// },
		computed: {
			...mapState(['theme', 'models']),
			fields () {
				return this.models.enrollments || {};
			},
			enrollment_attachs () {
				return this.fields.attachs || {};
			},
			enrollments_cfg () {
				return this.$utils.uploadConfig('enrollments');
			},
			is_show () {
				if (!this.enrollment.id) return false;
				if (this.enrollment.status == 2) return true;
				if (this.enrollment.status == 4) return true;
				return false;
			},
			title () {
				const { hired_at, separated_at } = this.enrollment;
				if (!hired_at) return "";
				var last = moment();
				var quitted = false;
				if (separated_at) {
					const separated = moment(separated_at);
					if (separated.add(1, 'days').isBefore(last)) {
						last = separated;
						quitted = true;
					}
				}
				const diff_days = Math.abs(last.diff(hired_at, 'days'));
				const years = Math.floor(diff_days / 365);
				const months = Math.floor((diff_days % 365) / 30.4375);
				const days = Math.floor((diff_days % 365) % 30.4375);
				var text = `累计 ${diff_days} 天`;
				if (months) {
					text = `${months} 月 ${days} 天。${text}`;
				}
				if (years) {
					text = `${years} 年。 ${text}`;
				}
				// 注意：以上代码中的% 30.4375是近似一个月30天的计算，对于大部分情况是准确的，但是在闰年二月会有误差，如果需要精确的月份差异，可以通过遍历每一个月来计算。
				if (quitted) {
					return `最后到岗：${separated_at} 任职 ${text}`;
				}
				return `已加入 ${text}`;
			},
			retirement_text () {
				const { id_number } = this.enrollment;
				if (!id_number) return "未知身份证号码，无法计算改革后退休年龄、退休时间与延迟月数";
				const { actualRetireAge, retirementYear, retirementMonth, delayMonths, originalAge, error } = this.$utils.calcRetirementYear(id_number);
				if (error) return "身份证号码有误，无法计算改革后退休年龄、退休时间与延迟月数";
				return `原退休年龄：${originalAge}岁，改革后退休年龄：${actualRetireAge}，退休时间：${retirementYear}年${retirementMonth}月, 延迟月数：${delayMonths}个月`;
			}
		},
		props: {
			person: {
				default: false,
				type: Boolean
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (enrollment = {}) {
				this.enrollment = {
					type: 1,
					gender: 1,
					nation: 1,
					id_type: 1,
					marriage: 1,
					politics: 13,
					education: 1,
					bank_number: '',
					attachments: [],
					...enrollment,
					name: enrollment.name ? enrollment.name.replace(/[^a-zA-Z\u4e00-\u9fa5]/g, '') : ''
				};
				this.enrollment_dialog = true;
			},
			onSuccess (list, meta) {
				const { attachments } = this.enrollment;
				// 保留其它附件
				const old = attachments.filter(a => a.meta != meta);
				this.enrollment = {
					...this.enrollment,
					attachments: {
						...old,
						...list.map((file) => {
							const { response } = file;
							file.meta = meta
							if (response) {
								const { status, id } = response.result;
								if (status == "success") {
									file.id = id;
								}
							}
							return file;
						})
					}
				} 
			},
			async submitEnrollment (data) {
				const res = await this.$http.post(this.$api.URI_EMPLOYEES_ENROLLMENTS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh');
						this.enrollment_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'enrollment_form':
							var { attachments } = this.enrollment;
							console.log(attachments);
							// this.submitEnrollment(this.enrollment);
						break;
					}
				});
			},
			onRemove ({ id }) {
				const { attachments } = this.enrollment;
				// 保留其它附件
				this.enrollment = {
					...this.enrollment,
					attachments: attachments.filter(a => a.id != id)
				}
			}
		},
		data() {
			const checkEmail = (rule, value, callback) => {
				const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的电子邮箱'));
				return callback();
			}
			const checkMobile = (rule, value, callback) => {
				const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的手机号'));
				return callback();
			}
			return {
				genders: {1: '男', 2: '女'},
				enrollment: {},
				enrollment_dialog: false,
				enrollment_rules: {
					name: [{ required: true, message: '必须填写真实姓名', trigger: 'blur'}],
					type: [{ required: true, message: '必须选择类型', trigger: 'blur'}],
					gender: [{ required: true, message: '必须选择性别', trigger: 'blur'}],
					email: [{ validator: checkEmail, trigger: ['blur', 'change'] }],
					mobile: [
						{ required: true, message: '必须填写手机号码', trigger: 'blur'},
						{ validator: checkMobile, trigger: ['blur', 'change'] }
					],
					education: [{ required: true, message: '必须填写教育情况', trigger: 'blur'}],
					id_number: [{ required: true, message: '必须填写证件号码', trigger: 'blur'}],
					emergency_name: [{ required: true, message: '必须填写紧急联系人', trigger: 'blur'}],
					emergency_mobile: [
						{ required: true, message: '必须填写手机号码', trigger: 'blur'},
						{ validator: checkMobile, trigger: ['blur', 'change'] }
					],
					residence_address: [{ required: true, message: '必须填写户籍地址', trigger: 'blur'}],
					current_address: [{ required: true, message: '必须填写现居地址', trigger: 'blur'}],
					birthed_at: [{ required: true, message: '必须填写出生日期', trigger: 'blur'}],
					hired_at: [{ required: true, message: '必须填写入职日期', trigger: 'blur'}],
				}
			}
		}
	};
</script>